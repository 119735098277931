import clsx from 'clsx'
import { ComponentProps, PropsWithChildren } from 'react'
import { useParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { NavigationTabs } from 'components/navigationTabs/NavigationTabs'
import styles from 'pages/components/tableNavWrapper/TableNavWrapper.module.scss'

interface Props extends ComponentProps<typeof Flex> {}

export const TableNavWrapper = ({ children, className, ...rest }: PropsWithChildren<Props>) => {
  const { '*': notRootRoute } = useParams()
  if (notRootRoute) return children

  return (
    <Flex direction="column" className={clsx(styles.container, className)} gap={15} {...rest}>
      <NavigationTabs className={styles.navigationTabs} />

      {children}
    </Flex>
  )
}
