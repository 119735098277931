// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bCV5h{flex:1 1;min-width:0}.p_FBg{flex-shrink:0;margin-right:16px}`, "",{"version":3,"sources":["webpack://./src/components/cellRenderers/avatarWithName/AvatarWithNameCell.module.scss"],"names":[],"mappings":"AAAA,OACE,QAAA,CACA,WAAA,CAGF,OACE,aAAA,CACA,iBAAA","sourcesContent":[".flexBaseWidth {\n  flex: 1;\n  min-width: 0;\n}\n\n.avatar {\n  flex-shrink: 0;\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexBaseWidth": `bCV5h`,
	"avatar": `p_FBg`
};
export default ___CSS_LOADER_EXPORT___;
