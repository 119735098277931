// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FFzQW{width:100%;margin:32px 0}.VNaLp{margin-right:auto}`, "",{"version":3,"sources":["webpack://./src/components/common/avatar/manageAvatarSideModal/ManageAvatarSideModal.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,aAAA,CAGF,OACE,iBAAA","sourcesContent":[".slider {\n  width: 100%;\n  margin: 32px 0;\n}\n\n.deleteButton {\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": `FFzQW`,
	"deleteButton": `VNaLp`
};
export default ___CSS_LOADER_EXPORT___;
