import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams, SortOrder } from 'api/common/types'
import { Agency } from 'types/masterData/agencies'

export enum AgenciesListSortBy {
  displayName = 'displayName',
  updatedAt = 'updatedAt',
  aliases = 'aliases',
  parents = 'parents',
}

type Params = PaginationParams<{
  search?: string
  sortBy?: AgenciesListSortBy
  orderBy?: SortOrder
}>

export type AgenciesListResponse = PaginatedResponse<Agency>

export const fetchAgenciesList = ({ search, page, itemsPerPage, sortBy, orderBy }: Params) =>
  facadeApi.get<AgenciesListResponse>('/agencies', {
    params: {
      page,
      itemsPerPage,
      sortBy,
      orderBy,
      filter: {
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
