import { WppButton } from '@platform-ui-kit/components-library-react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useCreateAgencyApi } from 'api/masterData/mutations/useCreateAgencyApi'
import { useEditAgencyApi } from 'api/masterData/mutations/useEditAgencyApi'
import { showDeleteAgencyModal } from 'pages/agencies/deleteAgencyModal/DeleteAgencyModal'
import { Tabs } from 'pages/agencies/upsert/tabs/UpsertTabs'
import { convertFormToFormDto } from 'pages/agencies/upsert/utils'
import { handleReloadAgenciesListTable } from 'pages/agencies/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Agency, AgencyForm } from 'types/masterData/agencies'
import { routesManager } from 'utils/routesManager'

interface Props {
  agency: Agency
  form: UseFormReturn<AgencyForm>
}

export const ActionButtons = ({ agency, form }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const [searchParams] = useSearchParams()
  const isClientsTab = searchParams.get('type') === Tabs.clients

  const { mutateAsync: handleCreate } = useCreateAgencyApi()
  const { mutateAsync: handleEdit } = useEditAgencyApi()

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: AgencyForm) => {
    try {
      const { data: createdAgency } = await handleCreate({
        agency: convertFormToFormDto(values),
      })

      navigate(routesManager.masterData.agencies.update.getURL({ entryId: createdAgency?.id }))

      await handleReloadAgenciesListTable()
      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.agency') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: AgencyForm) => {
    try {
      await handleEdit({
        id: agency!.id,
        agency: convertFormToFormDto(values),
      })

      await handleReloadAgenciesListTable(agency!.id)
      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.agency') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteAgencyModal({
      agency,
      onAfterDelete: () => {
        navigate(routesManager.masterData.agencies.root.getURL())
      },
    })
  }

  if (isClientsTab) return null

  return (
    <>
      {agency && (
        <WppButton variant="destructive-secondary" onClick={handleDelete}>
          {t('common.delete')}
        </WppButton>
      )}
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(agency ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )
}
