// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U5dX9{height:100%;padding-bottom:6px}.pwJ18{width:196px}`, "",{"version":3,"sources":["webpack://./src/pages/agencies/upsert/upsertClientsSideModal/UpsertClientsSideModal.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,kBAAA,CAGF,OACE,WAAA","sourcesContent":[".body {\n  height: 100%;\n  padding-bottom: 6px;\n}\n\n.searchInput {\n  width: 196px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `U5dX9`,
	"searchInput": `pwJ18`
};
export default ___CSS_LOADER_EXPORT___;
