import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListRegions } from 'pages/regions/list/ListRegions'
import { UpsertRegion } from 'pages/regions/upsert/UpsertRegion'
import { routesManager } from 'utils/routesManager'

export const Regions = () => (
  <Routes>
    <Route path={routesManager.masterData.regions.root.relativePattern} index element={<ListRegions />} />
    <Route path={routesManager.masterData.regions.create.relativePattern} element={<UpsertRegion />} />
    <Route path={routesManager.masterData.regions.update.relativePattern} element={<UpsertRegion />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
