interface URLParams {
  entryId: string
}

interface EntryRoute {
  pattern: string
  relativePattern: string
  getURL: (arg: URLParams) => string | (() => string)
}

interface MasterDataRouter {
  root: EntryRoute
  create: EntryRoute
  update: EntryRoute
}

export const routesManager = {
  home: {
    root: {
      pattern: '/',
      getURL: () => '/',
    },
  },
  masterData: {
    agencies: {
      root: {
        pattern: '/agencies/*',
        relativePattern: '/',
        getURL: () => '/agencies',
      },
      create: {
        pattern: '/agencies/create',
        relativePattern: '/create',
        getURL: () => '/agencies/create',
      },
      update: {
        pattern: '/agencies/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/agencies/${entryId}/update`,
      },
    },
    clients: {
      root: {
        pattern: '/clients/*',
        relativePattern: '/',
        getURL: () => '/clients',
      },
      create: {
        pattern: '/clients/create',
        relativePattern: '/create',
        getURL: () => '/clients/create',
      },
      update: {
        pattern: '/clients/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/clients/${entryId}/update`,
      },
    },
    brands: {
      root: {
        pattern: '/brands/*',
        relativePattern: '/',
        getURL: () => '/brands',
      },
      create: {
        pattern: '/brands/create',
        relativePattern: '/create',
        getURL: () => '/brands/create',
      },
      update: {
        pattern: '/brands/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/brands/${entryId}/update`,
      },
    },
    industries: {
      root: {
        pattern: '/industries/*',
        relativePattern: '/',
        getURL: () => '/industries',
      },
      create: {
        pattern: '/industries/create',
        relativePattern: '/create',
        getURL: () => '/industries/create',
      },
      update: {
        pattern: '/industries/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/industries/${entryId}/update`,
      },
    },
    markets: {
      root: {
        pattern: '/markets/*',
        relativePattern: '/',
        getURL: () => '/markets',
      },
      create: {
        pattern: '/markets/create',
        relativePattern: '/create',
        getURL: () => '/markets/create',
      },
      update: {
        pattern: '/markets/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/markets/${entryId}/update`,
      },
    },
    regions: {
      root: {
        pattern: '/regions/*',
        relativePattern: '/',
        getURL: () => '/regions',
      },
      create: {
        pattern: '/regions/create',
        relativePattern: '/create',
        getURL: () => '/regions/create',
      },
      update: {
        pattern: '/regions/:entryId/update',
        relativePattern: '/:entryId/update',
        getURL: ({ entryId }: URLParams) => `/regions/${entryId}/update`,
      },
    },
  } satisfies Record<string, MasterDataRouter>,
  '404': {
    root: {
      pattern: '/404',
      getURL: () => '/404',
    },
  },
} as const
