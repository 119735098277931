import { WppGrid } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormAgenciesAutocomplete } from 'components/form/formAutocomplete/FormAgenciesAutocomplete'
import { FormAliasesAutocomplete } from 'components/form/formAutocomplete/FormAliasesAutocomplete'
import { FormReadonlyPills } from 'components/form/formReadonlyPills/FormReadonlyPills'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { Agency } from 'types/masterData/agencies'

interface Props extends ComponentProps<typeof Flex> {
  agency: Agency
}

export const UpsertDetailsTab = ({ agency, ...rest }: Props) => {
  const { t } = useTranslation()

  return (
    <WppGrid fluid container>
      <WppGrid item all={16}>
        <Flex direction="column" gap={24} {...rest}>
          <ManageMasterDataLogoUpload name={agency?.displayName || ''} />
          <FormTextInput
            name="displayName"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormAliasesAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          <FormAgenciesAutocomplete
            name="parentId"
            multiple={false}
            labelConfig={{
              text: t('master_data.fields.parent.label', { entity: t('common.master_data.entities.agency') }),
              description: t('master_data.fields.parent.hint', {
                entity: t('common.master_data.entities.agency'),
                entities: t('common.master_data.entities.agency', { count: 2 }),
              }),
            }}
          />
          <FormReadonlyPills
            labelConfig={{
              text: t('master_data.fields.subsidiaries.label'),
              description: t('master_data.fields.subsidiaries.hint', {
                entity: t('common.master_data.entities.agency'),
                entities: t('common.master_data.entities.agency', { count: 2 }),
              }),
            }}
            name="children"
            getItemLabel={item => item.displayName}
          />
          <FormTextInput
            name="serviceName"
            labelConfig={{
              text: t('master_data.fields.service_name.label'),
            }}
            required
          />
          {agency?.meta && (
            <FormTextInput
              name="meta"
              disabled
              labelConfig={{
                text: t('master_data.fields.meta.label'),
                description: t('master_data.fields.meta.hint'),
              }}
              required
            />
          )}
        </Flex>
      </WppGrid>
    </WppGrid>
  )
}
