// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f6LGw{--input-select-dropdown-max-height: clamp(160px, 35vh, 372px)}@media(height < 701px){.f6LGw{--input-select-dropdown-max-height: clamp(120px, 25vh, 372px)}}.KRTSF{--input-select-dropdown-max-height: 30vh}.L0p45{margin:6px 0}`, "",{"version":3,"sources":["webpack://./src/components/common/select/Select.module.scss"],"names":[],"mappings":"AAAA,OACE,6DAAA,CAGF,uBACE,OACE,6DAAA,CAAA,CAKJ,OACE,wCAAA,CAGF,OACE,YAAA","sourcesContent":[".select {\n  --input-select-dropdown-max-height: clamp(160px, 35vh, 372px);\n}\n\n@media (height < 701px) {\n  .select {\n    --input-select-dropdown-max-height: clamp(120px, 25vh, 372px);\n  }\n}\n\n// TODO WPPLONOP-19180\n.withSearch {\n  --input-select-dropdown-max-height: 30vh;\n}\n\n.divider {\n  margin: 6px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `f6LGw`,
	"withSearch": `KRTSF`,
	"divider": `L0p45`
};
export default ___CSS_LOADER_EXPORT___;
