// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mqdsj{overflow:hidden;text-overflow:ellipsis}.Mqdsj ::part(item-text){overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/components/hierarchyBreadcrumbs/HierarchyBreadcrumbs.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,sBAAA,CAEA,yBACE,eAAA,CACA,sBAAA","sourcesContent":[".body {\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  ::part(item-text) {\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `Mqdsj`
};
export default ___CSS_LOADER_EXPORT___;
