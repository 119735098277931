// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zzgg8{order:2}.fUovk{height:100%}`, "",{"version":3,"sources":["webpack://./src/pages/components/tableNavWrapper/TableNavWrapper.module.scss"],"names":[],"mappings":"AAAA,OACE,OAAA,CAGF,OACE,WAAA","sourcesContent":[".navigationTabs {\n  order: 2;\n}\n\n.container {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigationTabs": `zzgg8`,
	"container": `fUovk`
};
export default ___CSS_LOADER_EXPORT___;
