import { WppTabsCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { TabsChangeEventDetail } from '@platform-ui-kit/components-library/dist/types/components/wpp-tabs/types'
import { WppTab, WppTabs } from '@platform-ui-kit/components-library-react'
import { ComponentProps, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import { Tooltip } from 'components/common/tooltip/Tooltip'
import styles from 'pages/agencies/upsert/tabs/UpsertTabs.module.scss'

interface Props extends Omit<ComponentProps<typeof WppTabs>, 'value'> {}

export enum Tabs {
  default = 'default',
  clients = 'clients',
}

export const UpsertTabs = ({ ...rest }: Props) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const openedTab = searchParams.get('type') || Tabs.default
  const { entryId } = useParams()

  const handleChange = useCallback(
    (event: WppTabsCustomEvent<TabsChangeEventDetail>) => {
      const value = event.detail.value as Tabs
      if (value === Tabs.default) {
        const updatedSearchParams = new URLSearchParams(searchParams)
        updatedSearchParams.delete('type')
        setSearchParams(updatedSearchParams)
      } else {
        setSearchParams({ type: value })
      }
    },
    [searchParams, setSearchParams],
  )

  return (
    <WppTabs value={openedTab} onWppChange={handleChange} {...rest} data-testid="agencies-upsert-tabs">
      <WppTab className={styles.tab} value={Tabs.default}>
        {t('navigation.agency.tabs.details.label')}
      </WppTab>
      <Tooltip text={t('navigation.agency.tabs.clients.hint')} enabled={!entryId}>
        <WppTab className={styles.tab} value={Tabs.clients} disabled={!entryId}>
          {t('navigation.agency.tabs.clients.label')}
        </WppTab>
      </Tooltip>
    </WppTabs>
  )
}
