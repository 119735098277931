import { useCallback } from 'react'

import { useFetchRegionsApi } from 'api/masterData/queries/useFetchRegionsApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { Region } from 'types/masterData/regions'

interface Params {
  search?: string
}

export const handleReloadRegionsListTable = async (entryId?: string) => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_REGIONS] }),
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_REGION, { entryId }] }),
  ])
  tableActions.reload(TableKey.MASTER_DATA_REGIONS)
}

export const useRegionsLoader = ({ search }: Params) => {
  const handleFetchRegionsList = useFetchRegionsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<Region> = useCallback(
    async ({ startRow, endRow }) => {
      const size = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchRegionsList({
        itemsPerPage: size,
        search,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchRegionsList],
  )

  return { loader }
}
