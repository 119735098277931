import { createApi } from 'api/common/createApi'
import config, { API_TYPES } from 'constants/app-config'

export const facadeApi = createApi({
  baseURL: '/api',
})

export const userDetailsApi = createApi({
  baseURL: '/_apps/user-details/api',
})

export const masterDataApi = createApi({
  baseURL: config[API_TYPES.WPP_MASTER_DATA_API],
})
