// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suwRE{width:-moz-fit-content;width:fit-content;color:var(--wpp-grey-color-800);cursor:pointer}.H8YjA::part(typography){color:var(--wpp-grey-color-800)}`, "",{"version":3,"sources":["webpack://./src/components/breadcrumbs/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAAA,iBAAA,CACA,+BAAA,CACA,cAAA,CAIA,yBACE,+BAAA","sourcesContent":[".backButton {\n  width: fit-content;\n  color: var(--wpp-grey-color-800);\n  cursor: pointer;\n}\n\n.buttonLabel {\n  &::part(typography) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": `suwRE`,
	"buttonLabel": `H8YjA`
};
export default ___CSS_LOADER_EXPORT___;
