// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mpstW{opacity:0;transition:.2s ease}.rmIh8{flex:1 1;min-width:0}.rmIh8:hover .mpstW{opacity:1}.QqxyE{min-width:0}`, "",{"version":3,"sources":["webpack://./src/components/common/clipboardText/ClipboardText.module.scss"],"names":[],"mappings":"AAAA,OACE,SAAA,CACA,mBAAA,CAGF,OACE,QAAA,CACA,WAAA,CAGE,oBACE,SAAA,CAKN,OACE,WAAA","sourcesContent":[".button {\n  opacity: 0;\n  transition: 0.2s ease;\n}\n\n.root {\n  flex: 1;\n  min-width: 0;\n\n  &:hover {\n    .button {\n      opacity: 1;\n    }\n  }\n}\n\n.text {\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `mpstW`,
	"root": `rmIh8`,
	"text": `QqxyE`
};
export default ___CSS_LOADER_EXPORT___;
