import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListMarkets } from 'pages/markets/list/ListMarkets'
import { UpsertMarket } from 'pages/markets/upsert/UpsertMarket'
import { routesManager } from 'utils/routesManager'

export const Markets = () => (
  <Routes>
    <Route path={routesManager.masterData.markets.root.relativePattern} index element={<ListMarkets />} />
    <Route path={routesManager.masterData.markets.create.relativePattern} element={<UpsertMarket />} />
    <Route path={routesManager.masterData.markets.update.relativePattern} element={<UpsertMarket />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
