import {
  AutocompleteOption,
  AutocompleteOptionId,
  AutocompleteLocales,
  GetOptionIdHandler,
  GetOptionLabelHandler,
  AutocompleteChangeEventDetail,
} from '@platform-ui-kit/components-library'
import { WppAutocomplete, WppListItem, WppPill } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentProps, forwardRef, ReactNode, Ref, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import styles from 'components/common/autocomplete/Autocomplete.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useCommonLabelProps } from 'components/common/utils'
import { useStableCallback } from 'hooks/useStableCallback'

export type AutocompleteProps<T extends AutocompleteOption = AutocompleteOption> = Omit<
  ComponentProps<typeof WppAutocomplete>,
  'getOptionId' | 'getOptionLabel' | 'value' | 'children'
> & {
  'data-testid'?: string
  value: T[]
  options: T[]
  getOptionId?: (option: T) => AutocompleteOptionId
  getOptionLabel?: (option: T) => string
  renderOptionContent?: (option: T) => ReactNode
  renderPillContent?: (value: T) => ReactNode
}

export type AutocompleteDropdownConfig = NonNullable<AutocompleteProps['dropdownConfig']>

const defaultGetOptionIdHandler = <T extends AutocompleteOption>(option: T) => option.id
const defaultGetOptionLabelHandler = <T extends AutocompleteOption>(option: T) => option.label

export const Autocomplete = forwardRef(function Autocomplete<T extends AutocompleteOption>(
  {
    options,
    getOptionId = defaultGetOptionIdHandler,
    getOptionLabel = defaultGetOptionLabelHandler,
    renderPillContent = getOptionLabel,
    renderOptionContent,
    className,
    value: values,
    showCreateNewElement = false,
    type = 'extended',
    disabled = false,
    labelConfig,
    labelTooltipConfig,
    locales,
    ...rest
  }: Omit<AutocompleteProps<T>, 'ref'>,
  ref: Ref<HTMLWppAutocompleteElement>,
) {
  const innerRef = useRef<HTMLWppAutocompleteElement>(null)
  const { t } = useTranslation()

  const defaultLocales = useMemo<AutocompleteLocales>(
    () => ({
      loading: t('common.autocomplete.loading'),
      nothingFound: t('common.autocomplete.nothing_found'),
      selected: count => t('common.autocomplete.selected', { count }),
      more: t('common.autocomplete.more'),
      createNewElement: t('common.autocomplete.create_new'),
    }),
    [t],
  )

  const onPillRemove = useStableCallback((value: T) => {
    innerRef.current?.dispatchEvent(
      new CustomEvent<AutocompleteChangeEventDetail>('wppChange', {
        bubbles: false,
        composed: false,
        detail: {
          value: values.filter(v => getOptionId(v) !== getOptionId(value)),
          reason: 'removeOption',
        },
      }),
    )
  })

  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

  return (
    <WppAutocomplete
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      {...labelProps}
      type={type}
      value={values}
      className={clsx(styles.autocomplete, className)}
      showCreateNewElement={showCreateNewElement}
      getOptionId={getOptionId as GetOptionIdHandler}
      getOptionLabel={getOptionLabel as GetOptionLabelHandler}
      locales={locales ? { ...defaultLocales, ...locales } : defaultLocales}
      disabled={disabled}
    >
      {options.map(option => (
        <WppListItem key={getOptionId(option)} value={option}>
          {renderOptionContent ? renderOptionContent?.(option) : <span slot="label">{getOptionLabel(option)}</span>}
        </WppListItem>
      ))}

      <Flex className={styles.value} slot="selected-values" gap={8} wrap="wrap">
        {values.map(value => (
          <WppPill
            disabled={disabled}
            key={getOptionId(value)}
            className={styles.pill}
            removable
            type="display"
            onWppClose={() => {
              onPillRemove(value)
            }}
          >
            {renderPillContent(value)}
          </WppPill>
        ))}
      </Flex>
    </WppAutocomplete>
  )
}) as <T extends AutocompleteOption>(props: AutocompleteProps<T>) => JSX.Element
