import { WppGrid } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentProps, PropsWithChildren } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/surface/layout/Layout.module.scss'

export const Layout = ({ children, className, ...rest }: PropsWithChildren<ComponentProps<typeof Flex>>) => (
  <Flex className={clsx(styles.root, className)} {...rest}>
    <WppGrid
      className={styles.gridContainer}
      container
      direction="column"
      alignItems="center"
      fullHeight
      rowSpacing={2}
    >
      <WppGrid className={styles.gridItem} item all={24}>
        {children}
      </WppGrid>
    </WppGrid>
  </Flex>
)
