import { WppButton, WppSlider, WppActionButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useRef } from 'react'
import AvatarEditor, { AvatarEditorProps, Position } from 'react-avatar-editor'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import styles from 'components/common/avatar/manageAvatarSideModal/ManageAvatarSideModal.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const DEFAULT_POSITION = { x: 0.5, y: 0.5 }

type Props = AvatarEditorProps &
  NiceModalWrappedProps & {
    onDelete?: () => Promise<void>
    onSave: (getCroppedFile: () => Promise<File>) => Promise<void>
    image: File | string
    minScale?: number
    maxScale?: number
    title?: string
  }

interface State {
  isSaving: boolean
  isDeleting: boolean
  scale: number
  position: Position
}

const ManageAvatarSideModal = ({
  isOpen,
  onCloseComplete,
  onClose,
  onDelete,
  image,
  onSave,
  title,
  width = 364,
  height = 364,
  border = 16,
  borderRadius = 1000,
  rotate = 0,
  minScale = 1,
  maxScale = 3,
  id,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  const editorRef = useRef<AvatarEditor>(null)

  const initialState = {
    isSaving: false,
    isDeleting: false,
    scale: minScale,
    position: DEFAULT_POSITION,
  }

  const [{ scale, position, isSaving, isDeleting }, setState] = useSetState<State>(initialState)

  const onSaveClick = async () => {
    setState({ isSaving: true })

    try {
      const getCroppedFile = async () =>
        await new Promise<File>(res => {
          editorRef.current!.getImageScaledToCanvas().toBlob(blob => {
            res(new File([blob as BlobPart], `avatar_${Date.now()}.png`, { type: 'image/png' }))
          })
        })

      await onSave(getCroppedFile)
    } catch {
      setState({ isSaving: false })
    }
  }

  const onDeleteClick = async () => {
    setState({ isDeleting: true })

    try {
      await onDelete?.()
    } catch {
      setState({ isDeleting: false })
    }
  }

  return (
    <SideModal
      data-testid={id}
      open={isOpen}
      disableOutsideClick
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
    >
      <WppTypography slot="header" type="2xl-heading">
        {title}
      </WppTypography>

      <Flex slot="body" direction="column">
        <AvatarEditor
          {...rest}
          image={image}
          ref={editorRef}
          width={width}
          height={height}
          border={border}
          borderRadius={borderRadius}
          scale={scale}
          rotate={rotate}
          position={position}
          crossOrigin="anonymous"
          onPositionChange={position => {
            setState({ position })
          }}
        />

        <WppSlider
          className={styles.slider}
          value={scale}
          min={minScale}
          max={maxScale}
          step={0.1}
          onWppChange={({ detail }) => {
            setState({ scale: detail.value as number })
          }}
        />
      </Flex>

      <Flex slot="actions" justify="end" gap={12}>
        {onDelete && (
          <WppActionButton
            className={styles.deleteButton}
            onClick={onDeleteClick}
            loading={isDeleting}
            variant="secondary"
          >
            {t('common.delete')}
          </WppActionButton>
        )}

        <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
          {t('common.cancel')}
        </WppButton>

        <WppButton variant="primary" loading={isSaving} onClick={onSaveClick} data-testid="apply">
          {t('common.save')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showManageAvatarSideModal, hideModal: hideManageAvatarSideModal } = createNiceModal(
  ManageAvatarSideModal,
  'manage-avatar-side-modal',
)
