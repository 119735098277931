import { BreadcrumbItemEventDetails } from '@platform-ui-kit/components-library'
import { WppBreadcrumbCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppBreadcrumb } from '@platform-ui-kit/components-library-react'
import { ComponentProps, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { TableEmptyCell } from 'components/common/table'
import styles from 'components/hierarchyBreadcrumbs/HierarchyBreadcrumbs.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props extends ComponentProps<typeof WppBreadcrumb> {
  data?: Hierarchy[]
  width?: number
  navigationTo: keyof typeof routesManager.masterData
}

export interface Hierarchy {
  id: string
  name: string
  parentId: string
}

const calcMaxLabelLength = (items: Hierarchy[], width: number) => {
  const coef = 500 / width
  const itemsLen = items.length
  switch (itemsLen) {
    case 1:
      return Number.MAX_SAFE_INTEGER
    case 2:
      return Math.floor(18 / coef)
    case 3:
      return Math.floor(12 / coef)
    case 4:
      return Math.floor(8 / coef)
    case 5:
      return Math.floor(6 / coef)
    default:
      return Math.floor(8 / coef)
  }
}

export const HierarchyBreadcrumbs = ({ data, width, navigationTo, ...rest }: Props) => {
  const navigate = useNavigate()
  const mappedItems = data?.map(item => ({ label: item.name, path: item.id }))?.reverse()

  const handleClick = useCallback(
    (event: WppBreadcrumbCustomEvent<BreadcrumbItemEventDetails>) => {
      const entryId = event.detail.path
      navigate(routesManager.masterData[navigationTo].update.getURL({ entryId }))
    },
    [navigate, navigationTo],
  )

  if (!data?.length) return TableEmptyCell

  return (
    <div className={styles.body}>
      <WppBreadcrumb
        items={mappedItems}
        onWppChange={handleClick}
        maxLabelLength={calcMaxLabelLength(data!, width!)}
        middleTruncation
        {...rest}
      />
    </div>
  )
}
