import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { useOs } from '@wpp-open/react'
import { BrowserRouter } from 'react-router-dom'

import { AppRoutes } from 'app/routes/AppRoutes'
import { Layout } from 'components/surface/layout/Layout'

export function App() {
  const { osContext } = useOs()

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <NiceModalProvider>
        <Layout>
          <AppRoutes />
        </Layout>
      </NiceModalProvider>
    </BrowserRouter>
  )
}
