import { useCallback, useState } from 'react'

import { useFetchGenerateMasterDataDownloadUrlsApi } from 'api/attachments/queryFetchers/useFetchGenerateMasterDataDownloadUrlsApi'
import { useStableCallback } from 'hooks/useStableCallback'

export const useMasterDataAttachments = (params?: Parameters<typeof useFetchGenerateMasterDataDownloadUrlsApi>[0]) => {
  const handleGenerateMasterDataDownloadUrls = useFetchGenerateMasterDataDownloadUrlsApi(params)
  const [map, updateMap] = useState<Record<string, string>>({})

  const handleLoadAttachments = useCallback(
    async (keys: string[]) => {
      if (keys.length) {
        const { data } = await handleGenerateMasterDataDownloadUrls({ keys })
        const portion = Object.fromEntries(data.map(({ key, signed_url }) => [key, signed_url]))

        updateMap(prev => ({
          ...prev,
          ...portion,
        }))
      }
    },
    [handleGenerateMasterDataDownloadUrls],
  )

  const getAttachmentUrlByKey = useStableCallback((key?: string) => map[key || ''] || '')

  return { handleLoadAttachments, getAttachmentUrlByKey }
}
