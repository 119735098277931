import { facadeApi } from 'api'
import { UploadInfo } from 'types/attachments/attachments'

interface Params {
  names: string[]
  contentType: string
}

export const generateMasterDataUploadUrlsApi = ({ names, contentType }: Params) =>
  facadeApi.post<UploadInfo[]>('/master-data-api/images/generate-temp-upload-urls', { names, contentType })
