import { WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from 'components/breadcrumbs/Breadcrumbs.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { RouteModules } from 'types/masterData/utils'
import { routesManager } from 'utils/routesManager'

export const Breadcrumbs = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const moduleName = pathname?.split('/')?.[1] as RouteModules

  return (
    <Flex
      onClick={() => navigate(routesManager.masterData[moduleName].root.getURL())}
      className={styles.backButton}
      align="center"
      gap={4}
      data-testid="breadcrumbs-back-button"
    >
      <WppIconChevron direction="left" />
      <WppTypography className={styles.buttonLabel} type="s-body">
        {t('navigation.back_to_entity', { entity: t(`tables.${moduleName}.title`) })}
      </WppTypography>
    </Flex>
  )
}
