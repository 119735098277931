import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchClientsList } from 'api/masterData/fetchers/fetchClientsList'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteClientsApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.MASTER_DATA_CLIENTS_INFINITE,
  fetcher: fetchClientsList,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: ({
    data: {
      paginator: { page, totalPages },
    },
  }) => (page < totalPages ? { page: page + 1 } : undefined),
})
