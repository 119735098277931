import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListClients } from 'pages/clients/list/ListClients'
import { UpsertClient } from 'pages/clients/upsert/UpsertClient'
import { routesManager } from 'utils/routesManager'

export const Clients = () => (
  <Routes>
    <Route path={routesManager.masterData.clients.root.relativePattern} index element={<ListClients />} />
    <Route path={routesManager.masterData.clients.create.relativePattern} element={<UpsertClient />} />
    <Route path={routesManager.masterData.clients.update.relativePattern} element={<UpsertClient />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
