// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CuxrZ{white-space:nowrap}.Dy8LX{width:130px}`, "",{"version":3,"sources":["webpack://./src/components/navigationTabs/NavigationTabs.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CAGF,OACE,WAAA","sourcesContent":[".wrapper {\n  white-space: nowrap;\n}\n\n.tab {\n  width: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CuxrZ`,
	"tab": `Dy8LX`
};
export default ___CSS_LOADER_EXPORT___;
