// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lbgmp{position:absolute;inset:0;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/components/surface/loadingPage/LoadingPage.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,OAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".container {\n  position: absolute;\n  inset: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `lbgmp`
};
export default ___CSS_LOADER_EXPORT___;
