import { WppActionButton, WppTooltip, WppTypography, WppIconCopy } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'react-use'

import styles from 'components/common/clipboardText/ClipboardText.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useToast } from 'providers/toast/ToastProvider'

export type ClipboardProps = ComponentPropsWithoutRef<typeof WppTypography> & {
  text: string
  toastMessage?: string
  tooltipMessage?: string
  'data-testid'?: string
}

export const ClipboardText = ({
  className,
  text,
  toastMessage,
  tooltipMessage,
  children,
  'data-testid': dataTestId = 'clipboard',
  ...rest
}: ClipboardProps) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const handleCopyClick = useCallback(
    (event: React.MouseEvent<HTMLWppActionButtonElement>) => {
      event.preventDefault()
      copyToClipboard(text)
      enqueueToast({
        message: toastMessage || t('common.toasts.copied_to_clipboard'),
        type: 'success',
      })
    },
    [copyToClipboard, enqueueToast, t, text, toastMessage],
  )

  return (
    <Flex
      className={clsx(className, styles.root)}
      gap={7}
      align="center"
      justify="between"
      inline
      data-testid={dataTestId}
    >
      <WppTypography type="s-body" className={styles.text} {...rest}>
        {children}
      </WppTypography>

      <WppTooltip text={tooltipMessage || t('common.copy_to_clipboard')}>
        <WppActionButton className={styles.button} onClick={handleCopyClick} data-testid="clipboard-button">
          <WppIconCopy />
        </WppActionButton>
      </WppTooltip>
    </Flex>
  )
}
