import { literal, ZodString, union, ZodNullable } from 'zod'
import * as zod from 'zod'

export const allowEmptyStringRule = <T extends ZodString>(param: T | ZodNullable<T>) =>
  union([literal('').transform(() => null), param])

export const autocompleteOptionType = zod.object({
  id: zod.string(),
  label: zod.string(),
})

export const fileType = zod.object({
  key: zod.string(),
  name: zod.string(),
  size: zod.number(),
})
