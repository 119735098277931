import { facadeApi } from 'api'

interface Params {
  signedUrl: string
  file: File
}

export const uploadMasterDataFileApi = ({ signedUrl, file }: Params) =>
  facadeApi.put<''>(signedUrl, file, {
    headers: { 'Content-Type': file.type || 'application/octet-stream' },
  })
