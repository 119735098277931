// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TcQvs{flex-shrink:0;--tag-neutral-bg-color: var(--wpp-dataviz-color-cat-light-1)}.TcQvs::part(label){color:var(--wpp-dataviz-color-cat-dark-1)}`, "",{"version":3,"sources":["webpack://./src/components/common/meTag/MeTag.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA,CAEA,4DAAA,CAEA,oBACE,yCAAA","sourcesContent":[".root {\n  flex-shrink: 0;\n\n  --tag-neutral-bg-color: var(--wpp-dataviz-color-cat-light-1);\n\n  &::part(label) {\n    color: var(--wpp-dataviz-color-cat-dark-1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `TcQvs`
};
export default ___CSS_LOADER_EXPORT___;
