import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import { useFetchAgencyApi } from 'api/masterData/queries/useFetchAgencyApi'
import { useForm } from 'hooks/form/useForm'
import { ActionButtons } from 'pages/agencies/upsert/components/ActionButtons'
import { UpsertClientsTab } from 'pages/agencies/upsert/components/UpsertClientsTab'
import { UpsertDetailsTab } from 'pages/agencies/upsert/components/UpsertDetailsTab'
import { Tabs, UpsertTabs } from 'pages/agencies/upsert/tabs/UpsertTabs'
import { defaultValues, convertToForm, useAgencyValidationScheme } from 'pages/agencies/upsert/utils'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { Agency } from 'types/masterData/agencies'

export const UpsertAgency = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isClientsTab = searchParams.get('type') === Tabs.clients

  const { entryId } = useParams()
  const { state } = useLocation()
  const agencyState = state?.data as MayBeNull<Agency>

  const {
    data: agencyData,
    error,
    isLoading,
  } = useFetchAgencyApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const agency = agencyData || agencyState

  const form = useForm({
    defaultValues: agency ? convertToForm(agency) : defaultValues,
    validationSchema: useAgencyValidationScheme(),
  })

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        title={t(`common.${agency ? 'update' : 'create'}_entity`, {
          entity: t('common.master_data.entities.agency'),
        })}
        actionButtons={<ActionButtons agency={agency!} form={form} />}
        tabsSection={<UpsertTabs />}
        wrappedCardContent={false}
        isLoading={isLoading}
        error={error}
      >
        {isClientsTab ? <UpsertClientsTab /> : <UpsertDetailsTab agency={agency!} />}
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
