export const TIME_FORMAT = 'h:mmaa'

export enum DATE_FORMAT {
  DD_MM_YYYY = 'dd/MM/yyyy',
  D_LLL = 'd LLL',
  DD_LLL_YYYY = 'dd LLL yyyy',
}

export enum DateFormat {
  'en-US' = 'MM/dd/yyyy ',
  'zh-cn' = 'yyyy/MM/dd',
  'de' = 'dd.MM.yyyy',
  'en-gb' = 'dd/MM/yyyy',
  'it' = 'dd/MM/yy',
}

export enum DateFormatLong {
  'en-US' = 'PPp',
  'zh-cn' = 'PPp',
  'de' = 'PPp',
  'en-gb' = 'PPp',
  'it' = 'PPp',
}
