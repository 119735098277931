// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gdpTA{width:100%;height:100%}.mTlYF{flex:1 1}`, "",{"version":3,"sources":["webpack://./src/components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CAGF,OACE,QAAA","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n}\n\n.info {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `gdpTA`,
	"info": `mTlYF`
};
export default ___CSS_LOADER_EXPORT___;
