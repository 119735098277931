import { WppActionButton, WppLabel, WppPill, WppPillGroup } from '@platform-ui-kit/components-library-react'
import { AttachmentMetadataResponse, MayBeNull } from '@wpp-open/core'
import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useCommonLabelProps } from 'components/common/utils'
import { AvatarWithLabelPill } from 'components/form/formAutocomplete/AvatarWithLabelPill'
import { usePaginatePills } from 'components/form/formReadonlyPills/usePaginatePills'
import { useField } from 'hooks/form/useField'

export interface Entity {
  id: string
  name: string
  logoThumbnail?: MayBeNull<AttachmentMetadataResponse>
  [rest: string]: any
}

interface Props<T extends Entity> extends Omit<ComponentPropsWithoutRef<typeof WppPillGroup>, 'name'> {
  name: string
  hideWhenEmpty?: boolean
  getItemLabel?: (option: T) => string
  getItemLogo?: (option: T) => string
  'data-testid'?: string
}

export const FormReadonlyPills = forwardRef<HTMLWppPillGroupElement, Props<Entity>>(function FormReadonlyPills<
  T extends Entity,
>(
  {
    name,
    labelConfig,
    labelTooltipConfig,
    hideWhenEmpty = true,
    getItemLabel = item => item?.name!,
    getItemLogo = item => item?.logoThumbnail?.url!,
    'data-testid': dataTestId,
    ...rest
  }: Props<T>,
  ref: Ref<HTMLWppPillGroupElement>,
) {
  const { t } = useTranslation()
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

  const {
    field: { value },
  } = useField({
    name,
  })

  const { shownPills, hasNextPage, showMore } = usePaginatePills<T>({ allValues: value, getItemLogo, getItemLabel })

  if (hideWhenEmpty && !value?.length) {
    return null
  }

  return (
    <Flex gap={8} direction="column" data-testid={dataTestId}>
      <WppLabel config={labelProps.labelConfig} tooltipConfig={labelProps.labelTooltipConfig} />
      <WppPillGroup type="display" ref={ref} {...rest}>
        <Flex wrap="wrap" gap={6}>
          {shownPills?.map(item => (
            <WppPill removable={false} key={item.id}>
              <AvatarWithLabelPill label={item.label} avatarUrl={item.avatarUrl} />
            </WppPill>
          ))}
          {/* FIXME: using conditional rendering cases a bug in CL, that's why style used here */}
          <WppActionButton style={{ display: hasNextPage ? 'unset' : 'none' }} variant="primary" onClick={showMore}>
            {t('common.show_more')}
          </WppActionButton>
        </Flex>
      </WppPillGroup>
    </Flex>
  )
}) as <T extends Entity>(props: Props<T>) => JSX.Element
