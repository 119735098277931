import { Navigate, Outlet } from 'react-router-dom'

import { useIsPermitted } from 'hooks/useIsPermitted'
import { AppPermissions } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

export const RoutePermittedAccess = ({ permission }: { permission: AppPermissions | AppPermissions[] }) => {
  const { isPermitted } = useIsPermitted()
  return isPermitted(permission) ? <Outlet /> : <Navigate replace to={routesManager['404'].root.pattern} />
}
