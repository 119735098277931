import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { Industry, IndustryForm, IndustryFormDTO } from 'types/masterData/industries'
import { autocompleteOptionType } from 'utils/zod'

export const defaultValues: IndustryForm = {
  name: '',
  aliases: [],
  meta: null,
}

export const convertToForm = (values: Industry): IndustryForm =>
  values && {
    ...values,
    aliases: (values.aliases || []).map(item => ({ id: item, label: item })),
    meta: values?.meta ? JSON.stringify(values.meta) : null,
  }

export const convertFormToFormDto = (values: IndustryForm): IndustryFormDTO => ({
  ...values,
  aliases: values.aliases.map(item => item.id.toString()),
})

export const nameFieldCharacterMaxCount = 256
export const nameFieldCharacterMinCount = 2

export const useIndustryValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        name: zod
          .string()
          .trim()
          .min(
            nameFieldCharacterMinCount,
            t('master_data.validation.name.min', {
              entity: t('common.master_data.entities.industry'),
              characterCount: nameFieldCharacterMinCount,
            }),
          )
          .max(
            nameFieldCharacterMaxCount,
            t('master_data.validation.name.max', {
              entity: t('common.master_data.entities.industry'),
              characterCount: nameFieldCharacterMaxCount,
            }),
          ),
        aliases: zod.array(zod.union([autocompleteOptionType, zod.string()])),
      }),
    [t],
  )
}
