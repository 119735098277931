// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UZwOx{min-height:100%}.HMSBT{display:flex;flex-grow:1}.HMSBT::part(card){flex-grow:1;min-height:100%}`, "",{"version":3,"sources":["webpack://./src/pages/components/upsertEntityWrapper/UpsertEntityWrapper.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAGF,OACE,YAAA,CACA,WAAA,CAEA,mBACE,WAAA,CACA,eAAA","sourcesContent":[".container {\n  min-height: 100%;\n}\n\n.cardWrapper {\n  display: flex;\n  flex-grow: 1;\n\n  &::part(card) {\n    flex-grow: 1;\n    min-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UZwOx`,
	"cardWrapper": `HMSBT`
};
export default ___CSS_LOADER_EXPORT___;
