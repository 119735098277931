import { WppAutocompleteCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { AutocompleteOptionList } from '@platform-ui-kit/components-library/dist/types/components/wpp-autocomplete/types'
import { ComponentProps, useCallback, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'

interface Props extends Omit<ComponentProps<typeof FormAutocomplete>, 'name' | 'options'> {
  name: string
}

export const FormAliasesAutocomplete = ({ name, ...rest }: Props) => {
  const { watch, setValue } = useFormContext()
  const values: AutocompleteOptionList = watch(name)
  const aliasesRef = useRef<HTMLWppAutocompleteElement>(null)

  const handleCreateNewAlias = useCallback(
    ({ detail: aliasText }: WppAutocompleteCustomEvent<string>) => {
      const newAlias = aliasText.trim()
      if (!newAlias) return
      if (!values.some(item => item.id === newAlias)) {
        const updatedValues: AutocompleteOptionList = [{ id: aliasText, label: aliasText }, ...values]
        setValue(name, updatedValues)
        // small hack, because `WppAutocomplete` does not allow to clean search or close dropdown programmatically
      }
      ;(aliasesRef.current as any).searchValue = ''
    },
    [values, setValue, name],
  )

  return (
    <FormAutocomplete
      ref={aliasesRef}
      simpleSearch
      name={name}
      onWppCreateNewOption={handleCreateNewAlias}
      multiple
      options={values}
      showCreateNewElement
      {...rest}
    />
  )
}
