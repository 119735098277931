import { WppAvatar } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

interface Props extends ComponentProps<typeof WppAvatar> {
  label: string
  avatarUrl: string
}

export const AvatarWithLabelPill = ({ label, avatarUrl, ...rest }: Props) => (
  <>
    <WppAvatar slot="icon-start" size="xs" name={label} src={avatarUrl} {...rest} />
    {label}
  </>
)
