import { WppCard, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { ForcedApiError } from 'api/common/types'
import { is404Error } from 'api/utils'
import { Breadcrumbs } from 'components/breadcrumbs/Breadcrumbs'
import { Flex } from 'components/common/flex/Flex'
import { LoadingPage } from 'components/surface/loadingPage/LoadingPage'
import { NotFound } from 'pages/404/NotFound'
import styles from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper.module.scss'

interface Props extends ComponentProps<typeof WppCard> {
  title: string
  actionButtons?: ReactNode
  tabsSection?: ReactNode
  className?: string
  isLoading?: boolean
  error?: MayBeNull<AxiosError | ForcedApiError>
  wrappedCardContent?: boolean
}

export const UpsertEntityWrapper = ({
  actionButtons,
  tabsSection,
  title,
  children,
  className,
  isLoading,
  error,
  wrappedCardContent = true,
  ...rest
}: PropsWithChildren<Props>) => {
  const { state } = useLocation()

  if (!state?.data && isLoading) {
    return <LoadingPage />
  }

  if (!isLoading && is404Error(error)) {
    return <NotFound />
  }

  return (
    <Flex direction="column" gap={24} className={styles.container}>
      <Flex direction="column" gap={8}>
        <Breadcrumbs />
        <Flex justify="between" align="center">
          <WppTypography type="3xl-heading">{title}</WppTypography>
          {actionButtons && <Flex gap={12}>{actionButtons}</Flex>}
        </Flex>
      </Flex>

      {tabsSection}

      <WppCard size="xl" className={clsx(styles.cardWrapper, className)} {...rest}>
        {wrappedCardContent ? (
          <WppGrid fluid container>
            <WppGrid item all={16}>
              {children}
            </WppGrid>
          </WppGrid>
        ) : (
          children
        )}
      </WppCard>
    </Flex>
  )
}
