import { SortOrder } from 'api/common/types'
import { AutocompleteOption } from 'types/common/utils'

export const isDevelop = process.env.NODE_ENV === 'development'

export const isString = (value: any): value is string => typeof value === 'string'
export const isNumber = (value: any): value is number => typeof value === 'number' && !isNaN(value)
export const isBoolean = (value: any): value is boolean => typeof value === 'boolean'
export const isDefined = <T>(value: T): value is Exclude<T, undefined> => value !== undefined
export const isFunction = (value: unknown): value is Function => typeof value === 'function'
export const isObject = (value: any): value is Record<string, any> => typeof value === 'object' && value !== null

export const safeParseJSON = (value: any) => {
  try {
    return JSON.parse(value)
  } catch {
    return null
  }
}

export function excludeFalsy<T>(value: T): value is Exclude<T, false | null | undefined | '' | 0> {
  return Boolean(value)
}

export const join = (value: (string | null | number | undefined)[], separator: string) =>
  value.filter(v => v !== 0 && excludeFalsy(v)).join(separator)

export const noop = () => {}

export const mapAutocompleteOptionsToStringValues = (options: AutocompleteOption[]) => options.map(({ id }) => id)

export const mapStringValuesToAutocompleteOptions = (values?: string[]): AutocompleteOption[] =>
  values ? values.map(value => ({ id: value, label: value })) : []

export const getObjectValuesCount = (obj: object) => Object.values(obj).filter(value => value.length).length

export const sortComparator =
  (order: SortOrder) =>
  <T extends string | number>(a: T, b: T) => {
    const value = isString(a) ? a.toLocaleLowerCase() : a
    const valueToCompare = isString(b) ? b.toLocaleLowerCase() : b

    if (value > valueToCompare) {
      return order === 'asc' ? 1 : -1
    }

    if (valueToCompare > value) {
      return order === 'asc' ? -1 : 1
    }

    return 0
  }

export const sortBy = <T, X extends string | number>(arr: T[], fn: (x: T) => X, order: SortOrder = 'asc') =>
  [...arr].sort((a, b) => sortComparator(order)(fn(a), fn(b)))

export const difference = <T, S, X extends string | number>(a: T[], b: S[], fn: (x: T | S) => X): T[] =>
  a.filter(aX => !b.some(bX => fn(aX) === fn(bX)))

export const intersection = <T, S, X extends string | number>(a: T[], b: S[], fn: (x: T | S) => X): T[] =>
  a.filter(aX => b.some(bX => fn(aX) === fn(bX)))

export const uniqueWith = <T>(arr: T[], fn: (a: T, b: T) => boolean): T[] =>
  arr.filter((element, index) => arr.findIndex(step => fn(element, step)) === index)

export const normalize = <T>(array: T[], keyExtractor: (item: T) => string): Record<string, T> =>
  array.reduce((normalized: Record<string, T>, item: T) => {
    const key = keyExtractor(item)
    normalized[key] = item
    return normalized
  }, {})

export const capitalize = (value: string) => value.replace(/^./, s => s.toUpperCase())

export const kebabize = (value: string) => value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)

export const maxBy = <T>(array: T[], fn: (x: T) => number) => Math.max(...array.map(fn))

export const minBy = <T>(array: T[], fn: (x: T) => number) => Math.min(...array.map(fn))

export const arraySum = (array: number[]) => array.reduce((acc, item) => acc + item, 0)

export const uniqBy = <T, X extends string | number>(array: T[], fn: (x: T) => X) =>
  Array.from(
    array
      .reduce((map, item) => {
        const key = fn(item)

        if (!map.has(key)) {
          map.set(key, item)
        }

        return map
      }, new Map<X, T>())
      .values(),
  )

export const emptyArray = (length: number): string[] => Array(length).fill('')

export const groupBy = <T, X extends string>(array: T[], fn: (x: T) => X) => {
  return Array.from(
    array
      .reduce((map, item) => {
        const key = fn(item)
        const collection = map.get(key)
        if (!map.has(key)) {
          map.set(key, [item])
        } else {
          collection!.push(item)
        }

        return map
      }, new Map<X, T[]>())
      .entries(),
  )
}
