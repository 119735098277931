import { WppButton } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useCreateRegionApi } from 'api/masterData/mutations/useCreateRegionApi'
import { useEditRegionApi } from 'api/masterData/mutations/useEditRegionApi'
import { useFetchRegionApi } from 'api/masterData/queries/useFetchRegionApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { useForm } from 'hooks/form/useForm'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { showDeleteRegionModal } from 'pages/regions/deleteRegionModal/DeleteRegionModal'
import { useRegionValidationScheme, defaultValues } from 'pages/regions/upsert/utils'
import { handleReloadRegionsListTable } from 'pages/regions/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { RegionForm, RegionFormDTO } from 'types/masterData/regions'
import { routesManager } from 'utils/routesManager'

export const UpsertRegion = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const { mutateAsync: handleCreate } = useCreateRegionApi()
  const { mutateAsync: handleEdit } = useEditRegionApi()

  const { entryId } = useParams()
  const { state } = useLocation()
  const regionState = state?.data

  const {
    data: regionData,
    isLoading,
    error,
  } = useFetchRegionApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const region = regionData || regionState

  const form = useForm({
    defaultValues: region || defaultValues,
    validationSchema: useRegionValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: RegionFormDTO) => {
    try {
      const { data: createdRegion } = await handleCreate({
        region: values,
      })
      navigate(routesManager.masterData.regions.update.getURL({ entryId: createdRegion?.id }))

      await handleReloadRegionsListTable()
      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.region') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: RegionForm) => {
    try {
      await handleEdit({
        id: region!.id,
        region: values,
      })

      await handleReloadRegionsListTable(region!.id)
      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.region') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteRegionModal({
      region,
      onAfterDelete: () => {
        navigate(routesManager.masterData.regions.root.getURL())
      },
    })
  }

  const ActionButtons = (
    <>
      {region && (
        <WppButton variant="destructive-secondary" onClick={handleDelete}>
          {t('common.delete')}
        </WppButton>
      )}
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(region ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        isLoading={isLoading}
        error={error}
        title={t(`common.${region ? 'update' : 'create'}_entity`, { entity: t('common.master_data.entities.region') })}
        actionButtons={ActionButtons}
      >
        <Flex direction="column" gap={24}>
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
