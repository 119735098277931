import { useMemo } from 'react'

import { useGenerateMasterDataUploadUrlsApi } from 'api/attachments/mutation/useGenerateMasterDataUploadUrlsApi'
import { useUploadMasterDataFileApi } from 'api/attachments/mutation/useUploadMasterDataFileApi'
import { createUploadCallback } from 'hooks/attachments/createUploadCallback'

export const useUploadMasterDataFilesApi = () => {
  const { mutateAsync: handleGenerateMasterDataUploadUrls } = useGenerateMasterDataUploadUrlsApi()
  const { mutateAsync: handleUploadMasterDataFile } = useUploadMasterDataFileApi()

  return useMemo(
    () =>
      createUploadCallback({
        generateUploadUrls: handleGenerateMasterDataUploadUrls,
        uploadFile: handleUploadMasterDataFile,
      }),
    [handleGenerateMasterDataUploadUrls, handleUploadMasterDataFile],
  )
}
