import { WppTooltip } from '@platform-ui-kit/components-library-react'
import { ComponentProps, PropsWithChildren } from 'react'

interface Props extends ComponentProps<typeof WppTooltip> {
  enabled?: boolean
}

export const Tooltip = ({ children, enabled = true, ...rest }: PropsWithChildren<Props>) => {
  if (!enabled) return children
  return <WppTooltip {...rest}>{children}</WppTooltip>
}
