import {
  WppIconEdit,
  WppIconMore,
  WppIconRemoveCircle,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppActionButton,
  WppButton,
  WppIconPlus,
} from '@platform-ui-kit/components-library-react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'

import { MarketsListSortBy } from 'api/masterData/fetchers/fetchMarketsList'
import { useRegionsApi } from 'api/masterData/queries/useRegionsApi'
import { AvatarWithNameCell } from 'components/cellRenderers/avatarWithName/AvatarWithNameCell'
import { AvatarWithInfoSkeleton } from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton'
import { ColDef, TableInfinite } from 'components/common/table'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useDateFormat } from 'hooks/useDateFormat'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { TablePageWrapper } from 'pages/components/tablePageWrapper/TablePageWrapper'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'
import { useTableNoRowsOverlay } from 'pages/hooks/useTableNoRowsOverlay'
import { showDeleteMarketModal } from 'pages/markets/deleteMarketModal/DeleteMarketModal'
import { useMarketsLoader } from 'pages/markets/utils'
import { Market as MarketType } from 'types/masterData/markets'
import { normalize } from 'utils/common'
import { hasClosestInteractiveElement } from 'utils/dom'
import { routesManager } from 'utils/routesManager'

interface State {
  isEmpty: boolean
  search: string
}

export const ListMarkets = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formatDate } = useDateFormat()
  const { data: regions } = useRegionsApi()

  const regionsById = useMemo(() => normalize(regions, item => item.id), [regions])

  const [{ search, isEmpty }, setState] = useSetState<State>({
    isEmpty: false,
    search: '',
  })

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '' }),
    Delay.Search,
  )

  const columnDefs = useMemo<ColDef<MarketType>[]>(
    () => [
      {
        flex: 1,
        colId: MarketsListSortBy.name,
        loadingCellRenderer: AvatarWithInfoSkeleton,
        cellRenderer: ({ data }) => {
          const { name, logoThumbnail, logoOriginal } = data!
          return <AvatarWithNameCell avatarThumbnail={logoThumbnail?.url || logoOriginal?.url || ''} userName={name} />
        },
        headerName: t('tables.markets.columns.name'),
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        flex: 1,
        colId: MarketsListSortBy.aliases,
        headerName: t('tables.markets.columns.aliases'),
        valueGetter: ({ data }) => data!.aliases.join(', ') ?? null,
        tooltipValueGetter: ({ data }) => data!.aliases.join(', ') ?? '-',
      },
      {
        flex: 1,
        colId: MarketsListSortBy.isoAlpha2,
        headerName: t('tables.markets.columns.iso_alpha_2'),
        valueGetter: ({ data }) => data!.isoAlpha2,
      },
      {
        flex: 1,
        colId: MarketsListSortBy.isoAlpha3,
        headerName: t('tables.markets.columns.iso_alpha_3'),
        valueGetter: ({ data }) => data!.isoAlpha3,
      },
      {
        flex: 1,
        colId: MarketsListSortBy.regionId,
        headerName: t('tables.markets.columns.region'),
        valueGetter: ({ data }) => (data!.regionId && regionsById[data!.regionId]?.name) || '',
      },
      {
        flex: 1,
        colId: MarketsListSortBy.updatedAt,
        valueGetter: ({ data }) => formatDate(data?.updatedAt!) || formatDate(data?.createdAt!),
        headerName: t('tables.markets.columns.updated'),
      },
      {
        width: 60,
        colId: 'actions',
        cellRenderer: ({ data }) => {
          return (
            <WppMenuContext
              className={styles.moreMenu}
              dropdownConfig={{
                appendTo: () => document.body,
                placement: 'bottom-end',
              }}
            >
              <WppActionButton slot="trigger-element">
                <WppIconMore direction="horizontal" />
              </WppActionButton>

              <WppListItem
                onWppChangeListItem={() =>
                  navigate(routesManager.masterData.markets.update.getURL({ entryId: data!.id }), {
                    state: { data },
                  })
                }
              >
                <WppIconEdit slot="left" />
                <span slot="label">{t('common.edit')}</span>
              </WppListItem>

              <WppListItem onWppChangeListItem={() => showDeleteMarketModal({ market: data! })}>
                <WppIconRemoveCircle slot="left" />
                <span slot="label">{t('common.delete')}</span>
              </WppListItem>
            </WppMenuContext>
          )
        },
      },
    ],
    [t, formatDate, navigate, regionsById],
  )

  const noRowsOverlayComponent = useTableNoRowsOverlay({ isEmpty, search })

  const handleOnRowClicked = useStableCallback(async ({ event, data }: RowClickedEvent<MarketType>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target)) {
      navigate(routesManager.masterData.markets.update.getURL({ entryId: data.id }), {
        state: { data },
      })
    }
  })

  const { loader } = useMarketsLoader({ search })

  const ActionButtons = (
    <WppButton variant="secondary" onClick={() => navigate(routesManager.masterData.markets.create.getURL())}>
      <WppIconPlus slot="icon-start" />
      {t('common.create')}
    </WppButton>
  )

  return (
    <TablePageWrapper actionButtons={ActionButtons}>
      <div slot="header">
        <WppInput
          size="s"
          type="search"
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
          className={styles.searchInput}
          placeholder={t('common.search')}
        />
      </div>

      <TableInfinite
        tableKey={TableKey.MASTER_DATA_MARKETS}
        className={clsx('markets-table', styles.table)}
        columnDefs={columnDefs}
        noRowsOverlayComponent={noRowsOverlayComponent}
        onRowClicked={handleOnRowClicked}
        loader={loader}
        onLoadSuccess={({ isEmptySource }) => {
          setState({ isEmpty: isEmptySource && !search })
        }}
      />
    </TablePageWrapper>
  )
}
