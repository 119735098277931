import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListIndustries } from 'pages/industries/list/ListIndustries'
import { UpsertIndustry } from 'pages/industries/upsert/UpsertIndustry'
import { routesManager } from 'utils/routesManager'

export const Industries = () => (
  <Routes>
    <Route path={routesManager.masterData.industries.root.relativePattern} index element={<ListIndustries />} />
    <Route path={routesManager.masterData.industries.create.relativePattern} element={<UpsertIndustry />} />
    <Route path={routesManager.masterData.industries.update.relativePattern} element={<UpsertIndustry />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
