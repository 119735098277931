import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListAgencies } from 'pages/agencies/list/ListAgencies'
import { UpsertAgency } from 'pages/agencies/upsert/UpsertAgency'
import { routesManager } from 'utils/routesManager'

export const Agencies = () => (
  <Routes>
    <Route path={routesManager.masterData.agencies.root.relativePattern} index element={<ListAgencies />} />
    <Route path={routesManager.masterData.agencies.create.relativePattern} element={<UpsertAgency />} />
    <Route path={routesManager.masterData.agencies.update.relativePattern} element={<UpsertAgency />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
