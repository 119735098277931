// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vt9Ca{margin-left:10px}.jxBpx::part(input)::-webkit-contacts-auto-fill-button{visibility:hidden;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/form/formTextInput/FormTextInput.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CAIA,uDACE,iBAAA,CACA,mBAAA","sourcesContent":[".tooltipError {\n  margin-left: 10px;\n}\n\n.input {\n  &::part(input)::-webkit-contacts-auto-fill-button {\n    visibility: hidden;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipError": `vt9Ca`,
	"input": `jxBpx`
};
export default ___CSS_LOADER_EXPORT___;
