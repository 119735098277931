import { isDevelop } from 'utils/common'
import { getDevEnv } from 'utils/dev'

export enum API_TYPES {
  WPP_MASTER_DATA_API = 'WPP_MASTER_DATA_API',
}

const env = getDevEnv() || 'hulk'
const developmentApi: Record<API_TYPES, string> = {
  WPP_MASTER_DATA_API: `https://master-data-api-ch-${env}.os-dev.io/api`,
}

const productionApi: Record<API_TYPES, string> = {
  WPP_MASTER_DATA_API: 'WPP_TEMPLATE_MASTER_DATA_API',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api = !isDevelop
    ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
    : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
