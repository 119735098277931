import { AttachmentMetadata } from '@wpp-open/core'
import { AxiosResponse } from 'axios'

import { UploadInfo } from 'types/attachments/attachments'

type GenerateUrlsFn = (params: { names: string[]; contentType: string }) => Promise<AxiosResponse<UploadInfo[]>>
type UploadFileFn = (params: { signedUrl: string; file: File }) => Promise<any>

export function createUploadCallback<G extends GenerateUrlsFn, U extends UploadFileFn>({
  generateUploadUrls,
  uploadFile,
}: {
  generateUploadUrls: G
  uploadFile: U
}) {
  return async function (files: File[]) {
    const uploadUrlsResponses = await Promise.all(
      files.map(file => generateUploadUrls({ names: [file.name], contentType: file.type })),
    )

    const entries = uploadUrlsResponses.map(({ data }, index) => [data[0], files[index]] as const)

    await Promise.all(
      entries.map(([{ signed_url }, file]) =>
        uploadFile({
          signedUrl: signed_url,
          file,
        }),
      ),
    )

    return entries.map(
      ([{ key }, file]): AttachmentMetadata => ({
        key,
        name: file.name,
        size: file.size,
      }),
    )
  }
}
